export interface MenuLink {
  title: string
  href?: string
  target?: string
  name?: string
  type?: 'external' | 'internal'
  sublinks?: MenuLink[]
}

const MenuLinks: MenuLink[] = [
  {
    // t('shared:navigationAbout')
    title: 'shared:navigationAbout',
    name: 'about',
    sublinks: [
      {
        // t('shared:navigationStats')
        title: 'shared:navigationStats',
        href: '/about/stats',
        name: 'stats',
      },
      {
        // t('shared:navigationContacts')
        title: 'shared:navigationContacts',
        href: '/about/contacts',
        name: 'contacts',
      },
    ],
  },
  {
    // t('shared:navigationRegulation')
    title: 'shared:navigationRegulation',
    name: 'regulation',

    sublinks: [
      {
        // t('shared:navigationWhatIs')
        title: 'shared:navigationWhatis',
        href: '/regulation/whatis',
        name: 'whatis',
      },
      {
        // t('shared:navigationAgreement')
        title: 'Terms and Conditions',
        href: '/regulation/terms-and-conditions',
        name: 'agreement',
      },
      {
        // t('shared:navigationPrivacy')
        title: 'shared:navigationPrivacy',
        href: '/regulation/privacy-policy',
        name: 'privacy',
      },
    ],
  },
  {
    // t('shared:navigationPartner')
    title: 'shared:navigationPartner',
    name: 'partner',

    sublinks: [
      {
        // t('shared:navigationProgram')
        title: 'shared:navigationProgram',
        type: 'external',
        href: 'https://binanypartner.com/',
        target: '_blank',
        name: 'partner',
      },
    ],
  },
]

export default MenuLinks
